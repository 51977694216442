import React from 'react'
import Footer from 'sparkol-web-component-library/src/components/footer'
import footerNavData from './footerNav.yaml'
import socialLinks from './socialLinks.yaml'
import util from 'sparkol-web-component-library/src/helpers/util'

import './footer.scss'

const PageFooter = () => {
  const copyrightProps = {
    caption: util.t(`${new Date().getFullYear()} Sparkol Group`),
    links: [
      { url: 'https://help.sparkol.com/support/solutions/folders/1000218934', caption: util.t('The legal stuff') }
    ]
  }
  return <Footer navData={footerNavData} socialData={socialLinks} copyrightData={copyrightProps} />
}

export default PageFooter
